import React, {useState} from 'react'
import { Button, Form, Input } from 'semantic-ui-react'

const Login = props => {
  const [username, setUsername] = useState(
    'Jenkins@microsoft.com'
  );
  const [password, setPassword] = useState(
    'password1'
  );

  const handleSubmit = () => {
    // console.log(`u=${username} p=${password}`)
    if (props.onSubmit) {
      props.onSubmit(username, password);
    } else {
      console.error('No submit handler for login.')
    }
  }

  return (
    <div>
      <h2>Login</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Input
              type="email"
              value={username}
              // placeholder={"Jenkins@microsoft.com"}
              onChange={event => setUsername(event.target.value)}/>
        </Form.Field>
        <Form.Field>
          <Input
              type="password"
              value={password}
              // placeholder={"password1"}
              onChange={event => setPassword(event.target.value)}/>
        </Form.Field>
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  )
}

export default Login