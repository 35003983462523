import styled from 'styled-components'
import theme from './design-tokens/theme'
import PropTypes from 'prop-types'
import React from 'react'

const TextFactory = styled.span`
  font-size: ${p => theme[`font_size_${p.size}`]};
  font-family: ${p => theme[`font_family_${p.family}`]};
`;

const Text = (props) => <TextFactory {...props} />

Text.propTypes = {
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  family: PropTypes.oneOf(['base', 'heading'])
}

Text.defaultProps = {
  size: 3,
  family: 'base'
}

export default Text;
