import { css } from 'styled-components'

export const defaults = {
  repeat: 'no-repeat',
  size: 'cover',
  position: 'center',
}

export const backgroundImage = ([
  src,
  size = defaults.size,
  position = defaults.position,
  repeat = defaults.repeat,
]) => {
  let props = {}
  src && (props.backgroundImage = `url(${src})`)
  size && (props.backgroundSize = size)
  position && (props.backgroundPosition = position)
  repeat && (props.backgroundRepeat = repeat)

  return css(props)
}
