import styled from 'styled-components'
import React from 'react'
import Grid from '../../ui/Grid'
import { Menu } from 'semantic-ui-react'
import LogoImage from '../../ui/design-tokens/LogoImage'
import Authenticate from '../auth/Auth'
import ds from '../../ui/tokens'

const Header = ({ pageNavItems = [] }) => {
  const handleMenuItemClick = () => {
  }

  return (
    <Grid withPageConstraints>
      <Grid.Col width={[12, 10]} widthOffset={[0, 1]}>
        <HeaderContainer>
          <Menu secondary stackable>
            <Menu.Item fitted={'horizontally'}>
              <LogoImage usage="pageHeader"/>
            </Menu.Item>
            <Menu.Menu position='right'>
              {pageNavItems.map(({name, displayName}, index) => {
                return <Menu.Item
                  key={index}
                  name={name}
                  onClick={handleMenuItemClick}
                >{displayName || name}</Menu.Item>

              })}

              <Authenticate>{(
                {
                  isAuthenticated,
                  userGreetName,
                  login,
                  logout,
                }) => isAuthenticated
                ? <Menu.Item
                  name='user'
                  onClick={logout}
                >
                  {userGreetName}
                </Menu.Item> : <Menu.Item
                  name='sign-in'
                  onClick={login}
                >
                  Sign-in
                </Menu.Item>}</Authenticate>
            </Menu.Menu>
          </Menu>

        </HeaderContainer>
      </Grid.Col>
    </Grid>
  )
}

export const HeaderContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  border-bottom: 1px solid ${ds.theme.colors.gray[40]};

  @media (max-width: ${ds.theme.screens.sm}) {
    height: auto;
  }
`

export default Header
