import React from 'react'
import Grid from './Grid'

export const Footer = ({children, id}) => {
  return (
    <Grid withPageConstraints className='flex flex-row justify-center'>
      <Grid.Col>
        <div id={id}>{children}</div>
      </Grid.Col>
    </Grid>
  )
}

export const FooterItem = ({children, id}) => {
  return (
    <div className="inline-block ml-3 text-base text-gray-0" id={id}>
      {children}
    </div>
  )
}


