import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { css } from 'linaria'
import styled from 'styled-components'
import { theme } from '../tokens'

export const Tabs = React.forwardRef(({ children, className }, ref) => {
  return (
    <div className={cx(tabs, className)} ref={ref}>
      <div className="pt-1 flex flex-wrap">{children}</div>
    </div>
  )
})

export const TabItem = ({ children, state, to, ...rest }) => {
  const Component = to ? StyledLink : Anchor
  return (<Component
    active={state === 'active'}
    hover={state === 'hover'}
    to={to}
    {...rest} css={tabItem} className={
    cx(style.tabCss, style.state[state])}
  >{children}</Component>)
}

export const TabDropdown = ({ show, ...rest }) => {
  return <div className={cx(tabDropdown, show && tabDropdown_show)}>
    {rest.children}
  </div>
}

const Anchor = styled.a`
  cursor: pointer;
`
const StyledLink = styled(Link)`
  cursor: pointer;
`

const tabDropdown = css`
  height: 0;
  min-width: 100%;
  opacity: 0;
  transition: opacity 200ms ease-in, height 200ms;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  overflow: hidden;
`

const tabDropdown_show = css`
  height: unset;
  opacity: 1;
`

const tabItem = css`
  cursor: pointer;
  white-space: nowrap;

  @media (min-width: ${theme.screens.sm}) {
    position: relative;
  }

  :focus {
    outline: none;
  }
`

const tabs = css`
  position: relative;

  > .${tabItem} {
    margin-right: 4px;
  }
`

const style = {
  // tab: css`
  //   white-space: nowrap;
  // `,
  tabCss: 'px-4 py-2 inline-block border-b-2 border-transparent font-normal text-black hover:text-pri-50',
  state: {
    active: 'text-pri-50 border-b-2 border-pri-50 font-medium',
    hover: '',
  },
}
