import { clearAuthentication, setAuthentication } from '../../state/auth'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { Modal } from '../../ui'
import Login from './Login'
import Cookies from 'js-cookie';
const {default: axios} = require('axios');

const Authenticate = (
  {
    auth,
    setAuthentication,
    clearAuthentication,
    children,
  }) => {

  const [authInProgress, setAuthInProgress] = useState(false)


  const login = () => {
    /**
     * Display popup with the login form.
     * */
    setAuthInProgress(true)
  }


  const logout = () => {
    clearAuthentication()
  }


  const getUserGreetName = (user) => {
    if (user.first_name) {
      return user.first_name;
    } else if (user.last_name) {
      return user.last_name;
    } else if (user.email) {
      return user.email;
    } else {
      return 'unnamed_user';
    }
  }


  const authProviderApi = {
    isAuthenticated: auth.isAuthenticated,
    userGreetName: auth.isAuthenticated ? getUserGreetName(auth.user) : '',
    authorisation: null,
    login,
    logout,
  }


  const handleAuthResponse = (rsp) => {
    // console.log(response);
    if (rsp && 200 === rsp.status) {
      Cookies.set(
        'czero-auth-token',
        rsp.data.authToken,
        {
          expires: rsp.data.token_expiry_days,
          sameSite: 'strict', // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        });
        // console.log(`response=${JSON.stringify(rsp)}`);

        setAuthInProgress(false);
        return setAuthentication({user: rsp.data.user});
    } else {
      console.error(`login errror: ${rsp}`)
    }
  }


  // const handleGetUserByTokenResponse = (rsp) => {
  //   // console.log(rsp.data);
  //   // TODO: handle failed request. e.g. 401, 403, 500, etc

  //   // return rsp.data;
  // }


  const setUserFromToken = async (token) => {
    const authToken = Cookies.get('czero-auth-token');
    // const user = await setUserFromToken(authToken);

    // let user = null;
    if (authToken) {
      // console.log(`token=${token}`)
      const axiosInst = axios.create({
        baseURL: 'http://localhost:3000/api/v0',
        headers: {
          Authorization: 'Bearer ' + authToken,
        }
      });

      return axiosInst.get('/user')
        .then(rsp => setAuthentication({user: rsp.data}))
        .catch(rsp => console.log(rsp))
    }
  }


  useEffect(() => {
    setUserFromToken();
  }, [setUserFromToken]);


  return <>
    {
      authInProgress && <Modal>
      <Login onSubmit={(username, password) => {
        const api = 'http://localhost:3000/api/v0';
        const payload = {
          email: username,
          password: password,
        };
        // console.log(`payload=${JSON.stringify(payload)}`);
        axios
          .post(api + '/login', payload)
          .then(handleAuthResponse)
          .catch(console.log)
      }} />
      </Modal>
    }
    {children(authProviderApi)}
  </>
}


// const mapStateToProps = state => ({
//   user: state.user,
// });


export default connect(state => state, {
  setAuthentication,
  clearAuthentication,
})(Authenticate)
