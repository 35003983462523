import { styled } from 'linaria/react'
import { css } from 'linaria'
import ReactModal from 'react-modal'
import React, { useEffect } from 'react'
import { noop } from '../../utils'
import close, { ReactComponent as Close } from '../../assets/icon/close.svg'
import { theme } from '../tokens'

const Modal = ({ isOpen, onRequestClose, ...props }) => {

  const showModal = Boolean(isOpen)

  useEffect(() => {
    if(showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showModal])

  return <ReactModal
    isOpen={Boolean(isOpen)}
    onRequestClose={onRequestClose}
    style={styles}
  >
    <ModalContainer dark>
      <ModalContentContainer>
        {props.children}
      </ModalContentContainer>
    </ModalContainer>
    <IconContainer onClick={onRequestClose}>
      <Close width={24} fill={theme.colors.dark[40]}/>
    </IconContainer>
  </ReactModal>
}

Modal.defaultProps = {
  onRequestClose: noop,
}

export default Modal

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0, 0.6)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '0',
    background: 'transparent',
  },
}

const IconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background: ${theme.colors.gray[100]};
  }
`

const ModalContentContainer = styled.div`
  max-height: calc(min(90vh, 1024px) - 120px);
  overflow: auto;
  
  @media (max-width: ${theme.screens.sm}) {
    max-height: calc(100% - ${theme.spacing[2]});
  }
`

const ModalContainer = styled.div`
  background: #FFF;
  max-width: 540px;
  max-height: min(90vh  , 1024px);
  padding: 40px;
  padding-top: 80px;
  border-radius: 8px;
  overflow: auto;

  @media (min-width: 1024px) {
    max-width: 800px;
  }
  
  @media (max-width: ${theme.screens.sm}) {
    max-width: 100%;
    max-height: 100%;
    width: 100vw;
    height: 100vh;
    padding: ${theme.spacing[8]};
    padding-top: ${theme.spacing[24]};
  }
`
