import React, { useEffect, useState } from 'react'
import { TabDropdown, TabItem, Tabs } from '../ui/Tabs/Tabs'
import { useLocation } from '@reach/router'
import HomeIcon from 'material-design-icons/action/svg/production/ic_home_48px.svg'

const NavigationHeader = ({ nav, className }) => {

  const location = useLocation()
  const [activeNav, setActiveNav] = useState(null)
  const [navTree, setNavTree] = useState([])
  const navRef = React.useRef(null)

  useEffect(() => {
    const activeNavNode = nav.find((n) => location.pathname === n.link)
    if (!activeNavNode) return
    const treeNodes = activeNavNode.name.split('/').filter(Boolean)
    if (treeNodes.length == 1) {
      setNavTree([activeNavNode.name])
    } else if (treeNodes.length === 2) {
      setNavTree([treeNodes[0], activeNavNode.name])
    }
  }, [location.pathname])

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (activeNav
        && navRef.current !== null
        && !navRef.current.contains(e.target)) {
        setActiveNav(null)
      }
    }

    if (activeNav) {
      window.addEventListener('click', pageClickEvent)
    }

    return () => {
      window.removeEventListener('click', pageClickEvent)
    }

  }, [activeNav])


  const headerStructure = nav.reduce((structureNav, item) => {
    const { name, label, link, isExternal } = item
    const navNames = item.name ? item.name.split('/') : [item.label]

    if (navNames.length == 1) {
      structureNav.items.push({ name, label, link, isExternal })
    }

    if (navNames.length == 2) {
      const parentNav = structureNav.items.find(({ name }) => name == navNames[0])
      if (parentNav) {
        parentNav.child = parentNav.child || { items: [] }
        parentNav.child.items.push(item)
      }
    }
    return structureNav
  }, {
    items: [],
  })

  const renderNav = ({ name, label, link, child }, index) => {
    return <TabItem
      state={navTree.includes(name) ? 'active' : null}
      to={link} tabIndex="0"
      onClick={() => {
        activeNav && activeNav == name ? setActiveNav(null) :
          child && setActiveNav(name)
      }}
    >
      <span>{label}</span>
      {child && <TabDropdown show={name == activeNav}>
        <div className="py-4 px-6 sm:p0 sm:pt-4 pb-10">
          <div className="bg-white rounded-md shadow-xl border-gray-60 border flex flex-col px-2 -mx-2 py-4">
            {child.items.map(renderNav)}
          </div>
        </div>
      </TabDropdown>
      }
    </TabItem>
  }

  return (
    <Tabs className={className} ref={navRef}>
      <TabItem
        state={location.pathname == '/' ? 'active' : false}
        to={'/'} tabIndex="0"
        onClick={() => setActiveNav('/')}
      >
        <span>
          <img src={HomeIcon} alt="" style={{
            height: 24
          }}/>
        </span>
      </TabItem>
      {headerStructure.items.map(renderNav)}
    </Tabs>
  )
}

export default NavigationHeader

