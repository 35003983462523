import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from '@reach/router'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import { HeaderContainer } from './Header'
import { connect } from 'react-redux'
import { Footer, FooterItem } from '../ui/Footer'
import LogoImage from '../ui/design-tokens/LogoImage'
import NavigationHeader from './NavigationHeader'
import SEO from './seo'

const Layout = ({ children }) => {

  const location = useLocation()

  return <StaticQuery
    query={graphql`
      query SiteQuery {
        allContentJson(filter: {type: {regex: "/(settings|header|footer)/m"}}) {
          nodes {
            type,
            site_title,
            copyrightText,
            navigation {
              name
              isExternal
              label
              link
            }
          }
        }
      }
    `}

    render={({allContentJson}) => {

      const headerJson = allContentJson.nodes.find(({ type }) => type === 'header')
      const footerJson = allContentJson.nodes.find(({ type }) => type === 'footer')
      const settingsJson = allContentJson.nodes.find(({ type }) => type === 'settings')

      return <>
        <SEO title={settingsJson.site_title}/>
        <HeaderContainer>
          <div
            className="flex flex-col flex-wrap items-start justify-center sm:flex-row sm:items-center sm:justify-between max-w-page mx-auto w-full">
            <Link to={'/'} className="inline-block ml-4 sm:ml-6 max-w-page">
              <LogoImage usage="pageHeader" id="header-logo-image"/>
            </Link>
            <NavigationHeader
              nav={headerJson.navigation}
              className="sm:mx-4 sm:mr-4 self-stretch px-4 sm:px-0 bg-gray-80 sm:bg-transparent mt-3 sm:mt-0"/>
          </div>
        </HeaderContainer>
        <PageContainer>
          <PageContent>
            {children}
          </PageContent>
          <PageFooter className="bg-gray-100">
            <Footer id="page-footer">
              <FooterItem>{footerJson.copyrightText}</FooterItem>
            </Footer>
          </PageFooter>
        </PageContainer>
      </>
    }}
/>
}

Layout.defaultProps = {}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
  auth: state.auth,
}))(Layout)

export const Section = styled.section`
  margin: 40px 0;
`

const PageContainer = styled.div`
  min-height: calc(100vh - 140px);
  padding-bottom: 60px;
  position: relative;
`

const PageContent = styled.div`  
`

const PageFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`
