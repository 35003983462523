import React from 'react'
import { css, cx } from 'linaria'
import logoImgSrc from '../../../assets/site/sandhaan-labs-2.4.0.png'

const LogoImage = (p) => {

  const imageStyles = [
    styles.logoImage,
  ]
  p.usage === 'pageHeader' && imageStyles.push(styles.pageHeader)

  return <img src={logoImgSrc} alt="Logo"
              id={p.id}
              className={cx(
                styles.logoImage,
                p.usage === 'pageHeader' && styles.pageHeader
              )}/>

}

const styles = {
  logoImage: css`
    display: inline-block;
    line-height: 0;
  `,
  pageHeader: css`
    height: 32px;
  `,
}

export default LogoImage
