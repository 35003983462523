import {px, rem} from "../../helper/styles";

// const palette = {
//   cold_purple: 'rgb(149,148,189)',
//   mike_blue: 'rgb(3,20,62)',
//   tangora: 'rgb(20,47,113)',
//   waikawa_Gray: 'rgb(90,112,163)',
//   tuft_bush: 'rgb(243,212,189)'
// }

const baseSize = 16;
// const scale = 1.125

const typeScale = {
  size1: 12.64,   // base * 1.125^-2
  size2: 14.22,   // base * 1.125^-1
  size3: 16.00,   // base
  size4: 18.00,   // base * 1.125^2
  size5: 20.25,   // base * 1.125^3
  size6: 22.78,   // base * 1.125^4
  size7: 25.63,   // base * 1.125^5
  size8: 36.83,   // base * 1.125^7
  size9: 46.18,   // base * 1.125^9
}

const Theme = {

  /**
   * Colors
   */
  color_primary: 'rgb(20,47,113)',
  color_background_primary: 'rgb(114,141,202)',
  color_border_inactive: 'rgb(203,203,203)',
  color_border_primary: 'rgb(20,47,113)',
  color_text_primary: 'rgb(20,47,113)',
  color_text_placeholder: 'rgb(145,145,145)',
  color_border_selection_active: 'rgb(114,141,202)',
  color_border_selection_hover: 'rgb(114,141,202)',

  /**
   * Fonts
   */
  font_family_base: 'Lato, Arial, sans-serif',
  font_family_heading: 'Montserrat, Arial, sans-serif',

  letter_spacing_default: '0.01rem',

  font_weight_bold: 700,
  font_weight_light: 300,
  font_weight_regular: 400,

  var_font_size_base: baseSize,
  var_font_size_1: typeScale.size1,
  var_font_size_2: typeScale.size2,
  var_font_size_3: typeScale.size3,
  var_font_size_4: typeScale.size4,
  var_font_size_5: typeScale.size5,
  var_font_size_6: typeScale.size6,
  var_font_size_7: typeScale.size7,
  var_font_size_8: typeScale.size8,
  var_font_size_9: typeScale.size9,

  font_size_1: typeScale.size1 + 'px',
  font_size_2: typeScale.size2 + 'px',
  font_size_3: typeScale.size3 + 'px',
  font_size_4: typeScale.size4 + 'px',
  font_size_5: typeScale.size5 + 'px',
  font_size_6: typeScale.size6 + 'px',
  font_size_7: typeScale.size7 + 'px',
  font_size_8: typeScale.size8 + 'px',
  font_size_9: typeScale.size9 + 'px',


  /**
   * Spacing
   */
  spacing: {
    border_thin: px(1),
    border_thick: px(2),
    pt4: rem(4),
    pt8: rem(8),
    pt12: rem(12),
    pt16: rem(16),
    pt24: rem(24),
    pt32: rem(32),
    pt64: rem(64),

    page_content_bound: rem(1440),
    page_gutter_width_small: rem(16),
    page_gutter_width_medium: rem(24),
    page_gutter_width_large: rem(32),
  },

  border_radius_large: rem(8),
  border_radius_medium: rem(4),
  border_radius_small: rem(2),
}

export default Theme;