import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { backgroundImage } from '../design-tokens/backgoungImage'
import Text from '../Text'

export default class Banner extends PureComponent {
  render() {
    const { bgImg, heroHeight } = this.props
    return <BannerContainer heroHeight={heroHeight}
                            bgImg={bgImg}>
      <Content>{this.props.children}</Content>
    </BannerContainer>
  }
}

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background: rgba(0,0,0,0.4);
`

export const BannerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${p => p.bgImg && backgroundImage(p.bgImg)};
`

BannerBackground.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(['cover', 'contain']),
  gravity: PropTypes.oneOf(['left', 'right']),
}

export const BannerHeroText = styled(Text)`
  margin-bottom: 0;
`

export const BannerTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const BannerImageContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
`

const BannerContainer = styled.div`
  min-height: ${p => p.heroHeight ? '320px' : 'none'};
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${p => p.bgImg && backgroundImage([p.bgImg])};
  
  @media(min-width: 768px) {
    min-height: ${p => p.heroHeight ? '440px' : 'none'};
  }
`
